import React from 'react';

import { CTASection } from '@containers';
import { Shape, ShapeWrapper } from '@components';
import { Box } from '@core';
import { useGlobalStateContext } from '@context';

import { ImageDesktop, ImageMobile, ImageTablet } from './components';

const getMapSrc = ({ userLocation, size = '530x300' }) =>
  userLocation &&
  `https://maps.googleapis.com/maps/api/staticmap?size=${size}&scale=2&format=jpg&maptype=roadmap&key=${process.env.GOOGLE_MAP_API_KEY}&markers=size:normal%7Ccolor:blue%7Clabel:P%7C${userLocation.lat},${userLocation.long}`;

const LocationCta = ({ data, images }) => {
  const { getIpLocation: userLocation } = useGlobalStateContext();

  return (
    <ShapeWrapper shape={Shape.Blob9} wrapperProps={{ top: { lg: -80 } }}>
      <Box>
        <CTASection
          data={data({ userLocation })}
          imageComponent={{
            mobile: (children) => (
              <ImageMobile mapSrc={getMapSrc({ userLocation, size: '300x200' })}>
                {children}
              </ImageMobile>
            ),
            tablet: (children) => (
              <ImageTablet
                mapSrc={getMapSrc({ userLocation, size: '360x280' })}
                image1={{
                  src: userLocation?.exit_ip ? images.protectedTablet : images.exposedTablet,
                  alt: 'protection status',
                }}
                image2={{
                  src: userLocation?.exit_ip
                    ? images.protectedIllustration
                    : images.exposedIllustration,
                  alt: 'protection status',
                }}
              >
                {children}
              </ImageTablet>
            ),
            desktop: (children) => (
              <ImageDesktop
                mapSrc={getMapSrc({ userLocation })}
                image={{
                  src: userLocation?.exit_ip ? images.ctaImageProtected : images.ctaImageExposed,
                  alt: 'protection status',
                }}
              >
                {children}
              </ImageDesktop>
            ),
          }}
          sectionProps={{
            wrapperProps: { pt: 0 },
          }}
          wrapperProps={{
            backgroundColor: '#BFE2FF',
            pt: 80,
          }}
          contentProps={{
            pt: 220,
            pb: 100,
          }}
          descriptionComponent={Box}
        />
      </Box>
    </ShapeWrapper>
  );
};

export default LocationCta;
